import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: { title: 'auth_login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { title: 'auth_login' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: { title: 'auth_logout' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Logout.vue'),
      },
    ],
  },

  {
    path: '/password',
    component: LayoutAuth,
    meta: { title: 'password' },
    redirect: '/password/reminder',
    hidden: true,
    children: [
      {
        path: 'reminder',
        name: 'PasswordReminder',
        meta: { title: 'password_reminder' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/password/Reminder.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: { title: 'top', icon: '' },
    children: [
      {
        path: '',
        name: 'TopIndex',
        meta: {
          title: 'top_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/top/Index.vue'),
      },
      {
        path: '/member',
        component: RouteWrapper,
        meta: {
          title: 'member',
          icon: 'face',
        },
        redirect: '/member/index',
        children: [
          {
            path: '/member/index',
            name: 'MemberIndex',
            meta: {
              title: 'member_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/member/Index.vue'),
          },
          {
            path: '/member/create',
            name: 'MemberCreate',
            meta: {
              title: 'member_create',
              icon: 'add',
            },
            component: () => import('@/views/member/Create.vue'),
          },
          {
            path: '/member/detail/:member_id',
            name: 'MemberDetail',
            meta: {
              title: 'member_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/member/Detail.vue'),
          },
        ],
      },
      {
        path: '/order',
        component: RouteWrapper,
        meta: {
          title: 'order',
          icon: 'receipt_long',
        },
        redirect: '/order/index',
        children: [
          {
            path: '/order/index',
            name: 'OrderIndex',
            meta: {
              title: 'order_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/order/Index.vue'),
          },
          {
            path: '/order/detail/:order_id',
            name: 'OrderDetail',
            meta: {
              title: 'order_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/order/Detail.vue'),
          },
        ],
      },
      {
        path: '/carousel',
        component: RouteWrapper,
        meta: {
          title: 'carousel',
          icon: 'recent_actors',
        },
        redirect: '/carousel/index',
        children: [
          {
            path: '/carousel/index',
            name: 'CarouselIndex',
            meta: {
              title: 'carousel_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/carousel/Index.vue'),
          },
          {
            path: '/carousel/create',
            name: 'CarouselCreate',
            meta: {
              title: 'carousel_create',
              icon: 'add',
            },
            component: () => import('@/views/carousel/Create.vue'),
          },
          {
            path: '/carousel/detail/:carousel_id',
            name: 'CarouselDetail',
            meta: {
              title: 'carousel_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/carousel/Detail.vue'),
          },
        ],
      },
      {
        path: '/notice',
        component: RouteWrapper,
        meta: {
          title: 'notice',
          icon: 'campaign',
        },
        redirect: '/notice/index',
        children: [
          {
            path: '/notice/index',
            name: 'NoticeIndex',
            meta: {
              title: 'notice_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/notice/Index.vue'),
          },
          {
            path: '/notice/create',
            name: 'NoticeCreate',
            meta: {
              title: 'notice_create',
              icon: 'add',
            },
            component: () => import('@/views/notice/Create.vue'),
          },
          {
            path: '/notice/detail/:notice_id',
            name: 'NoticeDetail',
            meta: {
              title: 'notice_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/notice/Detail.vue'),
          },
        ],
      },
      {
        path: '/tour',
        component: RouteWrapper,
        meta: {
          title: 'tour',
          icon: 'tour',
        },
        redirect: '/tour/index',
        children: [
          {
            path: '/tour/index',
            name: 'TourIndex',
            meta: {
              title: 'tour_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/tour/Index.vue'),
          },
          {
            path: '/tour/create',
            name: 'TourCreate',
            meta: {
              title: 'tour_create',
              icon: 'add',
            },
            component: () => import('@/views/tour/Create.vue'),
          },
          {
            path: '/tour/summary',
            name: 'TourSummary',
            meta: {
              title: 'tour_summary',
              icon: 'currency_yen',
            },
            component: () => import('@/views/tour/Summary.vue'),
          },
          {
            path: '/tour/detail/:tour_id',
            name: 'TourDetail',
            meta: {
              title: 'tour_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/tour/Detail.vue'),
          },
        ],
      },
      {
        path: '/option',
        component: RouteWrapper,
        meta: {
          title: 'option',
          icon: 'room_service',
        },
        redirect: '/option/index',
        children: [
          {
            path: '/option/index',
            name: 'OptionIndex',
            meta: {
              title: 'option_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/option/Index.vue'),
          },
          {
            path: '/option/create',
            name: 'OptionCreate',
            meta: {
              title: 'option_create',
              icon: 'add',
            },
            component: () => import('@/views/option/Create.vue'),
          },
          {
            path: '/option/detail/:option_id',
            name: 'OptionDetail',
            meta: {
              title: 'option_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/option/Detail.vue'),
          },
        ],
      },
      {
        path: '/referral_code',
        component: RouteWrapper,
        meta: {
          title: 'referral_code',
          icon: 'sell',
        },
        redirect: '/referral_code/index',
        children: [
          {
            path: '/referral_code/index',
            name: 'ReferralCodeIndex',
            meta: {
              title: 'referral_code_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/referral_code/Index.vue'),
          },
          {
            path: '/referral_code/create',
            name: 'ReferralCodeCreate',
            meta: {
              title: 'referral_code_create',
              icon: 'add',
            },
            component: () => import('@/views/referral_code/Create.vue'),
          },
          {
            path: '/referral_code/detail/:referral_code_id',
            name: 'ReferralCodeDetail',
            meta: {
              title: 'referral_code_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/referral_code/Detail.vue'),
          },
        ],
      },
      {
        path: '/faq',
        component: RouteWrapper,
        meta: {
          title: 'faq',
          icon: 'help',
        },
        redirect: '/faq/index',
        children: [
          {
            path: '/faq/index',
            name: 'FaqIndex',
            meta: {
              title: 'faq_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/faq/Index.vue'),
          },
          {
            path: '/faq/create',
            name: 'FaqCreate',
            meta: {
              title: 'faq_create',
              icon: 'add',
            },
            component: () => import('@/views/faq/Create.vue'),
          },
          {
            path: '/faq/detail/:faq_id',
            name: 'FaqDetail',
            meta: {
              title: 'faq_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/faq/Detail.vue'),
          },
        ],
      },
      {
        path: '/user',
        component: RouteWrapper,
        meta: {
          title: 'user',
          icon: 'person',
        },
        redirect: '/user/index',
        children: [
          {
            path: '/user/index',
            name: 'UserIndex',
            meta: {
              title: 'user_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/user/Index.vue'),
          },
          {
            path: '/user/create',
            name: 'UserCreate',
            meta: {
              title: 'user_create',
              icon: 'add',
            },
            component: () => import('@/views/user/Create.vue'),
          },
          {
            path: '/user/detail/:user_id',
            name: 'UserDetail',
            meta: {
              title: 'user_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/user/Detail.vue'),
          },
          {
            path: '/user/otp/:user_id',
            name: 'UserOtp',
            meta: {
              title: 'user_otp',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/user/Otp.vue'),
          },
        ],
      },
      {
        path: '/operation_history',
        component: RouteWrapper,
        meta: {
          title: 'operation_history',
          icon: 'history',
        },
        redirect: '/operation_history/index',
        children: [
          {
            path: '/operation_history/index',
            name: 'OperationHistoryIndex',
            meta: {
              title: 'operation_history_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/operation_history/Index.vue'),
          },
          {
            path: '/member_operation_history/index',
            name: 'MemberOperationHistoryIndex',
            meta: {
              title: 'member_operation_history_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/member_operation_history/Index.vue'),
          },
        ],
      },
    ],
  },
]
